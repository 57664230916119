<script setup lang="ts">
import { ref } from '#imports'

const props = defineProps<{
  isThin?: boolean
  itemsAlign?: 'start' | 'center' | 'end'
}>()

const open = ref(false)
const buttonClasses = computed(() => {
  const classes: Record<string, boolean> = {
    'bg-surface': !open.value,
    'bg-primary-50': open.value && !props.isThin,
    'p-5': !props.isThin,
    'space-x-2': !props.isThin,
  }

  const alignKey = props.itemsAlign ? `items-${props.itemsAlign}` : 'items-center'

  classes[alignKey] = true

  return classes
})
</script>

<template>
  <div class="flex flex-col items-start justify-start">
    <button
      class="flex w-full justify-between"
      :class="buttonClasses"
      :aria-expanded="open"
      type="button"
      @click="open = !open"
    >
      <div
        class="flex flex-1 flex-col items-start justify-center"
        :class="{ 'order-2': isThin }"
      >
        <slot v-bind="{ isOpen: open }" />
      </div>
      <div class="rounded-full p-1">
        <div
          class="i-ph-caret-down-bold h-4 w-4 transform-gpu rounded-full transition-transform"
          :class="{ 'rotate-180': open, 'rotate-0': !open }"
        />
      </div>
    </button>
    <div
      v-if="open"
      class="bg-surface w-full transition-all"
      :class="{ 'max-h-full': open, 'max-h-0': !open }"
      :hidden="!open"
    >
      <slot v-bind="{ isOpen: open }" name="content" />
    </div>
  </div>
</template>
